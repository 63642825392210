import React, { useState } from "react"
import ReactDOM from "react-dom"
import { auth } from "../../firebase"
import { toast } from "react-toastify"
import GoogleLogin from "../auth/GoogleLogin"
import "../widgets/loader.css"

const RegModal = ({ isShowing, hide }) => {
  const [email, setEmail] = useState("")
  const [loading, setLoading] = useState(false)

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    const config = {
      url: process.env.REACT_APP_REGISTER_REDIRECT_URL,
      handleCodeInApp: true,
    }

    await auth.sendSignInLinkToEmail(email, config)
    setLoading(false)
    toast.success(`גשו למייל שהזנתם וליחצו על הלינק ששלחנו לאישור ההרשמה.`)
    window.localStorage.setItem("registrationEmail", email)
    setEmail("")
  }

  const registerForm = () => (
    <div className="reg-form">
      {loading && <div className="loader" />}
      <h3 className="modal-inner-header">הרשמה חינם</h3>
      <form className="rigistration" onSubmit={handleSubmit}>
        <label htmlFor="email">דוא"ל: </label>
        <input
          autoFocus
          type="email"
          name="email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <button type="submit" className="red-btn">
          <span>שלח</span>
        </button>
        <span className="or">או</span>
        <GoogleLogin />
      </form>
    </div>
  )
  return isShowing
    ? ReactDOM.createPortal(
        <React.Fragment>
          <div className="modal-overlay" />
          <div
            className="modal-wrapper"
            aria-modal
            aria-hidden
            tabIndex={-1}
            role="dialog"
          >
            <div className="modal fade-in">
              <div className="modal-inner">
                <div className="modal-header">
                  <button
                    type="button"
                    className="modal-close-button"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={hide}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                {registerForm()}
              </div>
              {/* <div className="loader"></div> */}
            </div>
          </div>
        </React.Fragment>,
        document.body
      )
    : null
}
export default RegModal
