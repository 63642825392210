import React, { useState, useEffect } from "react"
import { auth } from "../../firebase"
import { useDispatch } from "react-redux"
import { createUpdateUser } from "../../helpers/auth"
import { toast } from "react-toastify"
import Layout from "../../hoc/Layout"

const INITIAL_VALUE = {
  email: "",
  password: "",
}

function RegisterComplete({ history }) {
  const [values, setValues] = useState(INITIAL_VALUE)
  let dispatch = useDispatch()

  useEffect(() => {
    setValues((prevState) => ({
      ...prevState,
      email: window.localStorage.getItem("registrationEmail"),
    }))
  }, [])

  const handleChange = (event) => {
    const { name, value } = event.target
    setValues((prevState) => ({ ...prevState, [name]: value }))
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (!values.email || !values.password) {
      toast.error("חובה להזין סיסמה ומייל")
      return
    }
    if (values.password < 6) {
      toast.error("סיסמה חייבת להכיל לפחות 6 תווים")
      return
    }
    try {
      const result = await auth.signInWithEmailLink(
        values.email,
        window.location.href
      )
      if (result.user.emailVerified) {
        window.localStorage.removeItem("registrationEmail")
        let user = auth.currentUser
        await user.updatePassword(values.password)
        const idTokenResult = await user.getIdTokenResult()
        const userInStorage = await user.getIdToken()

        createUpdateUser(idTokenResult.token)
          .then((res) => {
            dispatch({
              type: "LOGGED_IN_USER",
              payload: {
                email: res.data.email,
                token: idTokenResult.token,
                role: res.data.role,
                _id: res.data._id,
              },
            })
            window.localStorage.setItem(
              "user",
              JSON.stringify({ token: userInStorage, userId: res.data._id })
            )
          })
          .then(() => history.push("/user/dashboard"))
      }
    } catch (error) {
      console.log(error.response.status)
      toast.error(error.message)
    }
  }

  const completeRegistrationForm = () => (
    <div className="fade-in">
      <div className="login-main">
        <h3 className="inner-header">סיימו ההרשמה</h3>
        <form className="complition" onSubmit={handleSubmit}>
          <label htmlFor="email">דוא"ל: </label>
          <input
            type="email"
            name="email"
            id="email"
            value={values.email}
            disabled
          />
          <label htmlFor="password">בחרו סיסמה: </label>
          <input
            type="password"
            name="password"
            id="password"
            value={values.password}
            onChange={handleChange}
            placeholder="בחרו סיסמה"
            autoFocus
          />
          <button type="submit" className="red-btn">
            <span>סיים</span>
          </button>
        </form>
      </div>
    </div>
  )
  return (
    <Layout>
      <div id="show-case-home">
        <div className="complete-form">{completeRegistrationForm()}</div>
      </div>
    </Layout>
  )
}

export default RegisterComplete
