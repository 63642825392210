import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import axios from "axios"
import { useSelector } from "react-redux"
import { toast } from "react-toastify"
import useModal from "../modals/useModal"
import ModalQR from "../modals/modalQrCode"

function EditMenuButtons() {
  let history = useHistory()
  let { user, menu } = useSelector((state) => ({ ...state }))
  const [src, setSrc] = useState("")
  const authtoken = user.token
  const { isShowing, toggle } = useModal()

  // console.log("Menu", menu)

  const isAbleToQR = menu.data.theme

  // console.log(isAbleToQR)
  async function getQrCode() {
    const path = `/r/${menu.data._id}`
    try {
      const qrCode = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_API}/rqcode`,
        data: {
          path: path,
        },
        headers: {
          authtoken,
        },
      })
      setSrc(qrCode)
      toast.success(`קוד נוצר בהצלחה`)
      toggle()
    } catch (error) {
      // console.log("gen qrCode error", error.response.data.body)
      toast.error(error.response.data)
    }
  }
  return (
    <div className="buttons-row">
      {isAbleToQR ? (
        <button
          data-tootik="ניתן ליצור קוד"
          data-tootik-conf="success"
          onClick={getQrCode}
          type="btn"
          className="gray btn"
          style={{ color: "darkblue" }}
        >
          <span>צור QR-Code</span>
        </button>
      ) : (
        <button
          data-tootik="יש לבחור קודם עיצוב"
          data-tootik-conf="danger"
          disabled
          className="gray btn"
        >
          <span>צור QR-Code</span>
        </button>
      )}
      <ModalQR src={src.data} isShowing={isShowing} hide={toggle} />
    </div>
  )
}

export default EditMenuButtons
