import React from 'react';
import Footer from '../components/footer/Footer';
import Header from '../components/header/Header';

import './layout.css';

function Layout(props) {
  return (
    <div className='app'>
      <Header showInner={props.showInner} history={props} />
      <>{props.children}</>

      <Footer />
    </div>
  );
}

export default Layout;
