import React from "react"

function MainContent() {
  return (
    <div id="content">
      <div className="top-content">
        <div className="flag">
          <span>מה בתפריט?</span>
          <h2 className="title">הצטרפו להצלחה וחיסכו עוד היום</h2>
          <p className="text-center">
            תנו ללקוחות שלכם את החופש להחליט. הצטרפו עכשיו
            <strong> בחינם </strong>
            <br />
            ותתחילו ליצור בקלות את התפריט הדיגיטלי שלכם
          </p>
        </div>
        <div className="boxes">
          <div className="box">
            <img src="/images/qr-code.gif" alt="qr-code" />
            <div className="box-content">
              <h3>קוד ייחודי</h3>
              <p>
                בסיום התהליך תקבלו קוד יחודי למסעדה אותו תציגו ללקוחות שלכם וכך
                יוכלו לגשת לתפריט בקלות
              </p>
            </div>
          </div>
          <div className="box">
            <img src="/images/order.gif" alt="boxes" />
            <div className="box-content">
              <h3>עריכת פריטים</h3>
              <p>
                נהלו את התפריט שלכם בקלות וביעילות, הוסיפו פריטים והסירו אותם
                בכל עת
              </p>
            </div>
          </div>
          <div className="box">
            <img src="/images/headphone.gif" alt="headphone" />
            <div className="box-content">
              <h3>תמיכה בהקמה</h3>
              <p>
                אנחנו נעשה כל שביכולתנו על-מנת לסייע לכם בהקמת התפריט, נשמח
                לשמוע מכם הצעות ייעול
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MainContent
