import React from "react"
import { useSelector } from "react-redux"

function PickFreeColors({ selectColors }) {
  let { theme } = useSelector((state) => ({ ...state }))
  // console.log("In Color Picker", theme)
  return (
    <form className="colors-form">
      <div className="">
        <label className="colors-lable">
          <span>צבע עקרי:</span>
        </label>
        <input
          onChange={selectColors}
          type="color"
          name="menuColor"
          value={theme.menuColor}
        />
      </div>
      <div className="">
        <label className="colors-lable">
          <span>צבע משני:</span>
        </label>
        <input
          onChange={selectColors}
          type="color"
          name="accentColor"
          value={theme.accentColor}
        />
      </div>
    </form>
  )
}

export default PickFreeColors
