import React, { useState } from "react"
import ReactDOM from "react-dom"
import axios from "axios"
import catchErrors from "../utils/catchErrors"
import { useSelector } from "react-redux"
import { toast } from "react-toastify"

const INITIAL_STATE = {
  dish: "",
  title: "",
  desc: "",
  price: "",
}

const Modal = ({ isShowing, hide, catName, catId, menuId, refreshMenu }) => {
  const [isAdding, setIsAdding] = useState(true)
  const [values, setValues] = useState(INITIAL_STATE)

  const [loading, setLoading] = useState(false)

  const [error, setError] = useState("")

  let { user } = useSelector((state) => ({ ...state }))
  const authtoken = user.token

  // async function addDish() {
  //   await setIsAdding(!isAdding)
  // }
  async function handleAddDish(event) {
    // console.log("In handle Add Dish")
    event.preventDefault()
    try {
      setLoading(true)
      setError("")

      const dish = values.title
      const dPrice = values.price
      const dDesc = values.desc
      // console.log(category);
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_API}/dish/create`,
        data: {
          user,
          dPrice,
          dDesc,
          dish,
          menuId,
          catId,
        },
        headers: {
          authtoken,
        },
      })
      setValues(INITIAL_STATE)
      refreshMenu()
      toast.success(`מנה נוצרה בהצלחה`)
      hide()
    } catch (error) {
      catchErrors(error, setError)
      toast.error(`משהו השתבש`)
    } finally {
      setLoading(false)
      // console.log("Finally submited");
    }
  }
  const handleChange = (event) => {
    event.persist()
    setValues((previousValues) => ({
      ...previousValues,
      [event.target.name]: event.target.value,
    }))
  }

  return isShowing
    ? ReactDOM.createPortal(
        <React.Fragment>
          <div className="modal-overlay" />
          <div
            className="modal-wrapper"
            aria-modal
            aria-hidden
            tabIndex={-1}
            role="dialog"
          >
            <div className="modal fade-in">
              <div className="modal-inner">
                <div className="modal-header">
                  <button
                    type="button"
                    className="modal-close-button"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={hide}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>

                {isAdding ? (
                  <form onSubmit={handleAddDish}>
                    <div className="">
                      <div className="catName">
                        הוסף מנה ל<span>"{catName}"</span>
                      </div>
                    </div>
                    <div className="inputCenter">
                      <label>שם המנה</label>
                      <input
                        autoFocus
                        value={values.title}
                        placeholder="שם המנה"
                        onChange={handleChange}
                        name="title"
                        type="text"
                        style={{ margin: "0 auto" }}
                        required
                        lang="he"
                      />
                    </div>
                    <div className="inputCenter">
                      <label>תאור המנה</label>
                      <textarea
                        className="inputCenter"
                        value={values.desc}
                        style={{
                          // minHeight: 100,
                          width: "100%",
                          margin: "0 auto",
                        }}
                        placeholder="תאור המנה"
                        onChange={handleChange}
                        name="desc"
                        type="text"
                        required
                        lang="he"
                      />
                    </div>
                    <div className="inputCenter">
                      <label>מחיר</label>
                      <input
                        value={values.price}
                        placeholder="מחיר"
                        onChange={handleChange}
                        name="price"
                        type="number"
                        style={{ margin: "0 auto" }}
                        required
                        lang="he"
                      />
                    </div>
                    <button className="btn green" type="submit">
                      <span>שמור</span>
                    </button>
                  </form>
                ) : null}
              </div>
            </div>
          </div>
        </React.Fragment>,
        document.body
      )
    : null
}
export default Modal
