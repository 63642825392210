import axios from "axios"
import { toast } from "react-toastify"

// Get Menu

export async function getMenu(authtoken, setError204, user, dispatch) {
  console.log("in get Menu", user)
  const menuData = await axios({
    method: "post",
    url: `${process.env.REACT_APP_API}/menu`,
    data: { userId: user._id },
    headers: {
      authtoken,
    },
  })
  console.log(menuData)
  if (menuData.status === 204) {
    setError204("בואו נתחיל עם יצירת התפריט")
  }
  if (menuData.status === 401) {
    window.localStorage.removeItem("registrationEmail")
    window.localStorage.removeItem("user")
    setError204("יש להתחבר למערכת שוב")
  }
  let data = await menuData.data
  console.log("DATA ", data)
  if (data !== null) {
    dispatch({
      type: "GOT_MENU",
      payload: {
        data,
      },
    })
    if (data.theme !== null) {
      dispatch({
        type: "SET_THEME",
        payload: data.theme,
      })
    }
  }
  return data
}

export async function createMenu(event, values, authtoken, dispatch, user) {
  console.log("In create menu - 45", user)
  event.preventDefault()
  const restName = values.restName
  const phone = values.phone
  const contact = values.contact
  const userId = user

  const response = await axios({
    method: "POST",
    url: `${process.env.REACT_APP_API}/menu/create`,
    data: { name: restName, phone: phone, contact: contact, userId: userId },
    headers: {
      authtoken,
    },
  })
  const data = await response.data
  // console.log("From CreatMenu()", data)
  // setMenu(data)
  dispatch({
    type: "GOT_MENU",
    payload: {
      data,
    },
  })
  toast.success(`תפריט נוצר בהצלחה`)
}

// Update Menu Colors
export async function updateMenuColors(
  values,
  authtoken,
  menuId,
  dispatch,
  logout
) {
  try {
    const response = await axios({
      method: "PUT",
      url: `${process.env.REACT_APP_API}/menu/update-colors`,
      data: { values, menuId },
      headers: {
        authtoken,
      },
    })
    const data = await response.data
    // console.log("From CreatMenu()", data)
    // setMenu(data)
    dispatch({
      type: "GOT_MENU",
      payload: {
        data,
      },
    })
    toast.success(`צבעים עודכנו בהצלחה`)
    return data
  } catch (error) {
    if (error.response.status === 401) {
      console.log("401")
      logout()
    }
  }
}
// Update TodaySpecial
export async function updateTodaySpecial(
  menuId,
  title,
  text,
  price,
  authtoken,
  dispatch
) {
  const response = await axios({
    method: "PUT",
    url: `${process.env.REACT_APP_API}/menu/update-special`,
    data: {
      title,
      text,
      price,
      menuId,
    },
    headers: {
      authtoken,
    },
  })
  const data = await response.data
  // console.log("From CreatMenu()", data)
  // setMenu(data)
  dispatch({
    type: "GOT_MENU",
    payload: {
      data,
    },
  })
  toast.success(`מנת היום עודכנה בהצלחה`)
}
