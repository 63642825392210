import React from "react"
import { Route, Redirect } from "react-router-dom"
import LoadingToRedirect from "./LoadingToredirect"
import { useSelector } from "react-redux"

const PrivateRoute = ({ children, ...rest }) => {
  let user = JSON.parse(window.localStorage.getItem("user"))
  console.log("USER IN PRIVATE", user)

  return user ? <Route {...rest} /> : <LoadingToRedirect />

  // <Route
  //   {...rest}
  //   render={(props) =>
  //     user ? (
  //       <Component {...props} />
  //     ) : (
  //       // <h2>Oppps</h2>
  //       <Redirect
  //         to={{
  //           pathname: "/login",
  //           state: { from: props.location },
  //         }}
  //       />
  //     )
  //   }
  // ></Route>
}
export default PrivateRoute
