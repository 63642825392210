import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import firebase from "firebase"

const LoadingToRedirect = () => {
  const dispatch = useDispatch()
  const [count, setCount] = useState(4)
  let history = useHistory()
  const logout = () => {
    firebase.auth().signOut()
    window.localStorage.removeItem("registrationEmail")
    dispatch({
      type: "LOGOUT",
      payload: null,
    })
    history.push("/")
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setCount((currentCount) => --currentCount)
    }, 1000)
    // redirect once count is equal to 0
    count === 0 && logout()
    // cleanup
    return () => clearInterval(interval)
  }, [count, history])

  return (
    <div className="container p-5 text-center">
      <p>Redirecting you in {count} seconds</p>
    </div>
  )
}

export default LoadingToRedirect
