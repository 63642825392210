import React from "react"
import { persistStore } from "redux-persist"
import { PersistGate } from "redux-persist/integration/react"
import ReactDOM from "react-dom"
import Router from "./Router"
import { createStore } from "redux"
import { BrowserRouter } from "react-router-dom"
import { Provider } from "react-redux"
import rootReducer from "./reducers"

import "./index.css"

const store = createStore(rootReducer)

const presistor = persistStore(store)

const App = () => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <PersistGate persistor={presistor}>
          <Router />
        </PersistGate>
      </BrowserRouter>
    </Provider>
  )
}

ReactDOM.render(<App />, document.getElementById("root"))
