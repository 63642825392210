import React, { useState, useEffect } from "react"
import ModalDish from "../modals/modalDish"
import useModal from "../modals/useModal"
import { useHistory } from "react-router-dom"
import DishEdit from "./DishEdit"

const INITIAL_STATE = {
  title: "",
  description: "",
  price: "",
  catId: "",
}

function Dish({ dish, catId, menuId, refreshMenu, refresh, setRefresh }) {
  const [isEditing, setIsEditing] = useState(false)

  const { isShowing, toggle } = useModal()

  let history = useHistory()

  useEffect(() => {}, [refresh])
  return (
    <div className="catItem">
      <div className="dish-right">
        {isEditing ? (
          <DishEdit
            catId={catId}
            dish={dish}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            refresh={refresh}
            setRefresh={setRefresh}
            toggleDishEdit={toggle}
          />
        ) : (
          <div
            className="dots fade-in"
            onClick={() => setIsEditing(!isEditing)}
            data-tootik="עריכה / מחיקה"
            data-tootik-conf="left info"
          >
            {/* <img src="/images/3dots.svg" alt="" /> */}
            <img src="/images/3dots-45.svg" alt="" />
          </div>
        )}

        <div
          onClick={toggle}
          className="dish-name"
          data-tootik="ערוך מנה"
          data-tootik-conf="info"
        >
          <span> {dish.title} </span>- {dish.description}{" "}
        </div>
      </div>
      <div className="inner-cat">
        <div className="cat-right">
          <span className="price">{dish.price}</span>
        </div>

        <ModalDish
          catId={catId}
          isShowing={isShowing}
          hide={toggle}
          refreshMenu={refreshMenu}
          dish={dish}
        />
      </div>
    </div>
  )
}

export default Dish
