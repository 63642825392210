import firebase from 'firebase/app';
import 'firebase/auth';
// Your web app's Firebase configuration
const config = {
  apiKey: 'AIzaSyDHmH_DPCHE2EfMgw_J54BvkwgGTnrgruw',
  authDomain: 'mapenu-10998.firebaseapp.com',
  databaseURL: 'https://mapenu-10998.firebaseio.com',
  projectId: 'mapenu-10998',
  storageBucket: 'mapenu-10998.appspot.com',
  messagingSenderId: '1057973853211',
  appId: '1:1057973853211:web:789b11509bf92b42212cbe',
  measurementId: 'G-FEPXLMVF3H',
};
// initialize firebase app
if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

export const auth = firebase.auth();
export const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
