import React from "react"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
// import { CSSTransition } from "react-transition-group"
import RegModal from "../components/modals/regModal"
import useModal from "../components/modals/useModal"

function ShowCase({ history }) {
  const { isShowing, toggle } = useModal()
  let { user } = useSelector((state) => ({ ...state }))
  return (
    <div id="show-case-home" className="show-home">
      <RegModal isShowing={isShowing} hide={toggle} />

      <div className="main-pic">
        <div className="main-title">
          <h1 className="title">
            תפריט דיגיטלי
            <br />
            למסעדה שלך
          </h1>
          <p className="title-p">צרו תפריטים במהירות ובקלות</p>
          <p className="title-p">קבלו קוד ייחודי להורדת התפריט במסעדה</p>
          {user ? (
            <Link to="#" className="red-btn">
              <span>הצטרפו עכשיו</span>
            </Link>
          ) : (
            <div onClick={toggle} className="red-btn">
              <span>הצטרפו עכשיו</span>
            </div>
          )}
        </div>
        <div className="main-show">
          <img className="mover" src="/images/phone5.png" alt="phone" />
        </div>
      </div>
    </div>
  )
}

export default ShowCase
