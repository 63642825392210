import React, { useState, useEffect } from "react"
import { auth, googleAuthProvider } from "../../firebase"
import { useDispatch, useSelector } from "react-redux"

import { toast } from "react-toastify"
import axios from "axios"
import Layout from "../../hoc/Layout"
import "../../components/widgets/loader.css"
import "../../components/widgets/loader2.css"
import GoogleLogin from "../../components/auth/GoogleLogin"
import { Link } from "react-router-dom"

const createUpdateUser = async (authtoken) => {
  return await axios.post(
    `${process.env.REACT_APP_API}/create-update-user`,
    {},
    {
      headers: {
        authtoken,
      },
    }
  )
}

const INITIAL_VALUE = {
  email: "",
  password: "",
}

function Login({ history }) {
  const [values, setValues] = useState(INITIAL_VALUE)
  const [loading, setLoading] = useState(false)
  const [idToken, setIdToken] = useState("")
  const [email, setEmaiil] = useState("")
  let dispatch = useDispatch()
  let { user } = useSelector((state) => ({ ...state }))

  useEffect(() => {
    if (user && user.token) {
      user.role === "admin"
        ? history.push("/admin-panel")
        : history.push("/user/dashboard")
    }
  }, [user])

  const handleChange = (event) => {
    const { name, value } = event.target
    setValues((prevState) => ({ ...prevState, [name]: value }))
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    if (!values.email || !values.password) {
      toast.error("חובה להזין סיסמה ומייל")
      return
    }
    if (values.password < 6) {
      toast.error("סיסמה חייבת להכיל לפחות 6 תווים")
      return
    }
    try {
      const result = await auth.signInWithEmailAndPassword(
        values.email,
        values.password
      )
      const { user } = await result
      const idTokenResult = await user.getIdTokenResult()
      // console.log("******", idTokenResult)
      const userInStorage = await user.getIdToken()

      createUpdateUser(idTokenResult.token)
        .then((res) => {
          toast.success(`התחבת למערכת בהצלחה`)
          // console.log(res.data)
          dispatch({
            type: "LOGGED_IN_USER",
            payload: {
              email: res.data.email,
              token: idTokenResult.token,
              role: res.data.role,
              _id: res.data._id,
            },
          })
          window.localStorage.setItem(
            "user",
            JSON.stringify({ token: userInStorage, userId: res.data._id })
          )
        })
        .then(() => history.push("/user/dashboard"))
        .catch((error) => {
          console.log(error)
          toast.error(error.message)
        })
    } catch (error) {
      console.log(error)
      toast.error(error.message)
      setLoading(false)
    }
  }

  const loginForm = () => (
    <div className="fade-in">
      {loading && <div className="loader2"></div>}

      <div className="login-main">
        <h3 className="inner-header">הכנסו לממשק הניהול</h3>
        <form className="complition">
          <GoogleLogin />
        </form>
        <form className="complition" onSubmit={handleSubmit}>
          <h4 className="inner-header small-header">שם משתמש וסיסמה</h4>
          <label htmlFor="email">דוא"ל: </label>
          <input
            autoFocus
            type="email"
            name="email"
            id="email"
            value={values.email}
            onChange={handleChange}
            placeholder="שם משתמש"
          />
          <label htmlFor="password">סיסמה: </label>
          <input
            type="password"
            name="password"
            id="password"
            value={values.password}
            onChange={handleChange}
            placeholder="סיסמה"
          />
          <Link to="/forgot/password">שחזור סיסמה?</Link>
          <button
            disabled={!values.email || values.password < 6}
            type="submit"
            className="red-btn"
          >
            <span>הכנס</span>
          </button>
        </form>
      </div>
    </div>
  )
  return (
    <Layout>
      <div id="show-case-home">
        <div className="complete-form">{loginForm()}</div>
      </div>
    </Layout>
  )
}

export default Login
