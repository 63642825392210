import React, { useState } from "react"
import { Link, NavLink, withRouter } from "react-router-dom"
import { useHistory } from "react-router-dom"
import Menu from "./Menu"
import SideDrawer from "./Menu/SideDrawer/SideDrawer"
import BackDrop from "./Menu/Backdrop/BackDrop"
import RegModal from "../modals/regModal"
import useModal from "../modals/useModal"
import firebase from "firebase"
import { useDispatch, useSelector } from "react-redux"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.min.css"

import logo from "./logo3.svg"
import "../widgets/loader.css"

const HeaderTop = (props) => {
  const [isOpen, setIsOpen] = useState(false)
  const { isShowing, toggle } = useModal()
  const history = useHistory()
  let dispatch = useDispatch()
  let { user, menu } = useSelector((state) => ({ ...state }))
  // console.log(user)
  const drawerToggleOnClick = () => {
    setIsOpen(!isOpen)
  }
  const backdropClick = () => {
    setIsOpen(false)
  }

  const logOut = () => {
    firebase.auth().signOut()
    window.localStorage.removeItem("registrationEmail")
    window.localStorage.removeItem("user")
    dispatch({
      type: "LOGOUT",
      payload: null,
    })
    history.push("/")
  }

  return (
    <div id="header">
      <ToastContainer position="top-left" />

      <RegModal isShowing={isShowing} hide={toggle} />
      <div className="menu">
        <div className="logCon">
          {user && (
            <Link to="/user/dashboard">
              <div className="logo2">
                <img src={logo} alt="לוגו" />
              </div>
            </Link>
          )}
          {!user && (
            <>
              <Link to="/">
                <div className="logo2">
                  <img src={logo} alt="לוגו" />
                </div>
              </Link>
              <Link className="about-nav" to="/about">
                <span>אודות</span>
              </Link>
            </>
          )}
        </div>

        <div className="menuItems">
          {user ? (
            <>
              {menu.data.menu !== "" && (
                <>
                  <Menu drawerToggleOnClick={drawerToggleOnClick} />
                  <SideDrawer
                    drawerToggleOnClick={drawerToggleOnClick}
                    isOpen={isOpen}
                    onClick={logOut}
                  />
                  {isOpen && <BackDrop click={backdropClick} />}
                </>
              )}
            </>
          ) : (
            <>
              <div>
                <NavLink className="item in" name="login" exact to="/login">
                  הכנס
                </NavLink>
              </div>
              <div>
                <NavLink
                  onClick={toggle}
                  className="item reg"
                  name="signup"
                  to="#"
                >
                  הרשם
                </NavLink>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="break"></div>
    </div>
  )
}
export default withRouter(HeaderTop)
