import React, { useState, useEffect } from "react"
import axios from "axios"

import Layout from "../../hoc/Layout"
import { useDispatch, useSelector } from "react-redux"
import MainSettings from "../../components/user/MainSettings"
import EditMenuButtons from "../../components/widgets/EditMenuButtons"

const INITIAL_STATE = {}

const Private = ({ history }) => {
  const [values, setValues] = useState(INITIAL_STATE)

  let dispatch = useDispatch()

  let { user, menu } = useSelector((state) => ({ ...state }))

  console.log("******user", user)
  useEffect(() => {
    if (user !== null) {
      const authtoken = user.token
      const loadProfile = () => {
        axios({
          method: "GET",
          url: `${process.env.REACT_APP_API}/user/${user._id}`,
          headers: {
            authtoken,
          },
        })
          .then((response) => {
            console.log("Profile update response", response)
            const { role, name, email, _id } = response.data
            setValues({ ...values, role, name, email, _id })
          })
          .catch((error) => {
            console.log("Profile update Error", error)
            if (error.response.status === 401) {
              dispatch({
                type: "LOGOUT",
                payload: null,
              })
              history.push("/")
              console.log("error", error)
            }
          })
      }
      loadProfile()
    }
  }, [])

  return (
    <>
      {user !== null ? (
        <Layout showInner={values.name}>
          <div id="show-case" className="panel">
            <div className="screen">
              <div className="topSec">
                <EditMenuButtons />
              </div>
              <div>
                <h3 className="modal-inner-header">{menu.data.restName}</h3>
              </div>
              <MainSettings
                role={values.role}
                name={values.name}
                id={values._id}
              />
            </div>
          </div>
        </Layout>
      ) : (
        <Layout direction={"rtl"}>
          <div>
            <h2 style={{ textAlign: "center" }}>יש להתחבר שוב למערכת</h2>
          </div>
        </Layout>
      )}
    </>
  )
}

export default Private
