import React, { useState } from "react"
import ReactDOM from "react-dom"
import catchErrors from "../utils/catchErrors"
import axios from "axios"
import { useSelector } from "react-redux"
import { toast } from "react-toastify"
import { useHistory } from "react-router-dom"

const Modal = ({ catToDelete, isShowing, hide, refresh, setRefresh }) => {
  const cat = catToDelete
  let { user, menu } = useSelector((state) => ({ ...state }))
  let history = useHistory()
  const [error, setError] = useState("")

  const menuId = menu.data.menuId
  const authtoken = user.token

  async function hanleDelete(cat) {
    const catId = cat._id
    const category = cat.category
    try {
      const response = await axios({
        method: "DELETE",
        url: `${process.env.REACT_APP_API}/category/delete`,
        data: { catId, menuId, category },
        headers: {
          authtoken,
        },
      })
      toast.success(`נמחק בהצלחה`)
      setRefresh(!refresh)
      hide()
    } catch (error) {
      toast.error("אופס, יש תקלה")
      catchErrors(error, setError)
    }
  }

  return isShowing
    ? ReactDOM.createPortal(
        <React.Fragment>
          <div className="modal-overlay" />
          <div
            className="modal-wrapper"
            aria-modal
            aria-hidden
            tabIndex={-1}
            role="dialog"
          >
            <div className="modal fade-in">
              {cat && (
                <div className="active-modal">
                  <div className="modal-inner">
                    <div className="modal-header">
                      <button
                        type="button"
                        className="modal-close-button"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={hide}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <h3>
                      בטוח למחוק את {""} {cat.title}??
                    </h3>
                    <div className="buttonsModel">
                      <button
                        onClick={() => hanleDelete(cat)}
                        style={{ marginBottom: "1em" }}
                        className="btn red-btn"
                      >
                        <span>מחק</span>
                      </button>
                      <button
                        onClick={() => {
                          history.goBack()
                        }}
                        className="btn gray"
                      >
                        <span>בטל</span>
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </React.Fragment>,
        document.body
      )
    : null
}

export default Modal
