import React from "react"
import MenuItems from "./MenuItems"
import { Link } from "react-router-dom"
import DrawerToggle from "./SideDrawer/DrawerToggle"

export default function Menu({ drawerToggleOnClick }) {
  return (
    <nav className="toolbar">
      <div>
        <DrawerToggle drawerToggleOnClick={drawerToggleOnClick} />
      </div>
      <div className="spacer" />
    </nav>
  )
}
