import React, { useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Link, useHistory } from "react-router-dom"
import axios from "axios"
import { toast } from "react-toastify"
import useModal from "../../modals/useModal"
import ModalQR from "../../modals/modalQrCode"
import preon1 from "./images/preon1.svg"
import edit from "./images/edit1.svg"
import design from "./images/design.svg"
import special from "./images/special.svg"
import info from "./images/info.svg"
import support from "./images/support.svg"
import logout from "./images/logout.svg"
import qr from "./images/qr.svg"

export default function MenuItems({ drawerToggleOnClick, onClick }) {
  let history = useHistory()
  let { user, menu } = useSelector((state) => ({ ...state }))
  const { isShowing, toggle } = useModal()
  const [src, setSrc] = useState("")
  let isDesignAble = menu.data.categories.length > 0
  let isSpecialAble = menu.data.theme

  const authtoken = user.token
  // console.log(isDesignAble)
  async function getQrCode() {
    const path = `/r/${menu.data._id}`
    try {
      const qrCode = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_API}/rqcode`,
        data: {
          path: path,
        },
        headers: {
          authtoken,
        },
      })
      setSrc(qrCode)
      toast.success(`קוד נוצר בהצלחה`)
      toggle()
    } catch (error) {
      // console.log("gen qrCode error", error.response.data.body)
      toast.error(error.response.data)
    }
  }
  return (
    <>
      <ModalQR src={src.data} isShowing={isShowing} hide={toggle} />
      <div className="top-drawer">
        <h3>{menu.data.restName}</h3>
      </div>
      <Link to="/user/settings" onClick={drawerToggleOnClick}>
        <li>
          <img className="link-drawer" src={preon1} alt="איזור אישי איקון" />
          <span>איזור אישי</span>
        </li>
      </Link>
      <Link to="/user/dashboard" onClick={drawerToggleOnClick}>
        <li>
          <img className="link-drawer" src={edit} alt="עריכת תפריט איקון" />
          <span>עריכת התפריט</span>
        </li>
      </Link>
      <Link
        className={isDesignAble ? "" : "is-disabled"}
        to={`/rest-edit/${menu.data._id}`}
        onClick={drawerToggleOnClick}
      >
        <li>
          <img className="design" src={design} alt="איקון עיצוב" />
          <span>עיצוב התפריט</span>
        </li>
      </Link>
      <Link
        className={isSpecialAble ? "" : "is-disabled"}
        to={`/today-special/${menu.data._id}`}
        onClick={drawerToggleOnClick}
      >
        <li>
          <img className="link-drawer" src={special} alt="איקון מנת היום" />
          <span>מנת היום</span>
        </li>
      </Link>

      <Link
        className={isSpecialAble ? "" : "is-disabled"}
        to="#"
        onClick={(drawerToggleOnClick, getQrCode)}
      >
        <li>
          <img className="link-drawer" src={qr} alt="איקון מנת היום" />
          <span>QR-Code</span>
        </li>
      </Link>

      <Link to="/about" onClick={drawerToggleOnClick}>
        <li>
          <img className="link-drawer" src={info} alt="אודות איקון" />
          <span>אודות</span>
        </li>
      </Link>
      <Link to="/support" onClick={drawerToggleOnClick}>
        <li>
          <img className="link-drawer" src={support} alt="תמיכה איקון" />
          <span>תמיכה</span>
        </li>
      </Link>
      <Link
        style={{ color: "#474747" }}
        to="/"
        onClick={(drawerToggleOnClick, onClick)}
      >
        <li>
          <img className="link-drawer" src={logout} alt="איזור אישי איקון" />
          <span>התנתק</span>
        </li>
      </Link>
    </>
  )
}
