import React from "react"
import "./restLayout1.css"

export default function RestLayout(props) {
  return (
    <div style={{ backgroundColor: props.bgColor }} className="menu-page">
      {props.children}
    </div>
  )
}
