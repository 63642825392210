import React from "react"
import logo from "../header/logo3.svg"

function Footer() {
  return (
    <div id="footer" className="footer-main">
      <div className="logo2">
        <img src={logo} alt="לוגו" />
      </div>
      <div>
        <p>כל הזכויות שמורות © גל אגאי 2021</p>
      </div>
    </div>
  )
}

export default Footer
