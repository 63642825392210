import React, { useState } from "react"
import RestLayout from "../../../hoc/RestLayout"
import RestCategory from "../../../components/restaurants/RestCategory"
import top1 from "./images/change2.png"
import logo from "./images/change1.png"
import HeadBasicOne from "./head/HeadBasicOne"

function BasicOne({ menu }) {
  // const [menu, setMenu] = useState(INITIAL_STATE)
  // console.table(menu[0])
  let menuColor = "#7f5a42"
  return (
    <RestLayout>
      <HeadBasicOne logo={logo} top1={top1} menuColor={menuColor} />
      {menu ? (
        <div id="menu-main1">
          {menu.categories.map((cat, index) => (
            <RestCategory cat={cat} index={index} key={cat._id} />
          ))}
        </div>
      ) : (
        <>
          <h2>null</h2>
        </>
      )}

      <div style={{ backgroundColor: menuColor }} id="menu-footer">
        כל הזכויות שמורות © גל אגאי 2021
      </div>
    </RestLayout>
  )
}

export default BasicOne
