import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { createMenu, getMenu } from "../../components/utils/requestFunctions"
import catchErrors from "../../components/utils/catchErrors"
// import { toast } from "react-toastify"
import Layout from "../../hoc/Layout"
import CatList from "../../components/user/CatList"
import FirstStep from "../../components/user/FirstStep"
import useModal from "../../components/modals/useModal"
import Modal from "../../components/modals/modal"

const INITIAL_STATE = {
  restName: "",
  phone: "",
  contact: "",
  category: "",
}

function UserHome({ history }) {
  const dispatch = useDispatch()
  const { isShowing, toggle } = useModal()
  // const [restName, setRestName] = useState("")
  const [catToDelete, setCatToDelete] = useState(null)
  const [values, setValues] = useState(INITIAL_STATE)
  const [validMessage, setValidMess] = useState("")
  const [refresh, setRefresh] = useState(false)
  const [error, setError] = useState("")
  const [error204, setError204] = useState("")
  let { menu } = useSelector((state) => ({ ...state }))
  let state = useSelector((state) => ({ ...state }))
  let userFromStorage = JSON.parse(window.localStorage.getItem("user"))

  let user = { _id: userFromStorage.userId }

  // console.log("UserHome --vALUES", values)
  // console.log("UserHome ---menu", menu)
  // console.log("UserHome ----State", state)

  const authtoken = userFromStorage.token

  // console.log("UserHome ****", user.token, "**********", userFromStorage.token)

  useEffect(() => {
    const numberPattern = /^[0-9]*$/
    if (!values.phone.match(numberPattern)) {
      setValidMess("נא להזין רק ספרות")
      return
    }
    if (
      (values.phone.length !== 0 && values.phone.length < 9) ||
      values.phone.length > 12
    ) {
      setValidMess("נא להזין מספר טלפון תקין")
      // console.log(values.phone.length);
      return
    }
    if (values.phone === "") {
      setValidMess("")
      return
    } else {
      setValidMess("")
    }
  }, [values])

  useEffect(() => {
    try {
      getMenu(authtoken, setError204, user, dispatch)
      // console.log("home", menu)
    } catch (error) {
      console.log(error)
      catchErrors(error, setError)
    }
  }, [refresh])

  const handleChange = (event) => {
    event.persist()
    setValues((previousValues) => ({
      ...previousValues,
      [event.target.name]: event.target.value,
    }))
  }

  const handleSubmit = async (event) => {
    try {
      await createMenu(event, values, authtoken, dispatch, user)
      await getMenu(authtoken, setError204, user, dispatch)
    } catch (error) {
      catchErrors(error, setError)
    }
  }

  const hadleDelete = (cat) => {
    toggle()
    setCatToDelete(cat)
  }

  return (
    <Layout>
      <div id="show-case" className="panel">
        {menu.data.menu === "" && (
          <>
            <h2>התחל ביצירת תפריט!</h2>
            <FirstStep
              handleSubmit={handleSubmit}
              handleChange={handleChange}
              validMessage={validMessage}
              error204={error204}
            />
          </>
        )}
        {menu.data.menu !== "" && (
          <div>
            <Modal
              isShowing={isShowing}
              hide={toggle}
              catToDelete={catToDelete}
              refresh={refresh}
              setRefresh={setRefresh}
            />
            <CatList
              menu={menu.data}
              catList={menu.data.categories}
              hadleDelete={hadleDelete}
              refresh={refresh}
              setRefresh={setRefresh}
            />
          </div>
        )}
      </div>
    </Layout>
  )
}

export default UserHome
