import { combineReducers } from "redux"
import { persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"
import { userReducer } from "./userReducer"
import { menuReducer } from "./menuReducer"
import { themeReducer } from "./themeReducer"

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["theme", "menu", "user"],
}

const rootReducer = combineReducers({
  user: userReducer,
  menu: menuReducer,
  theme: themeReducer,
})

export default persistReducer(persistConfig, rootReducer)
