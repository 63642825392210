import React, { useState, useEffect } from "react"
import ReactDOM from "react-dom"
import axios from "axios"
import catchErrors from "../utils/catchErrors"
import { toast } from "react-toastify"
import { useSelector } from "react-redux"

const INITIAL_STATE = {
  title: "",
  description: "",
  price: "",
  dishId: "",
}

const ModalDish = ({ isShowing, hide, catName, refreshMenu, dish }) => {
  // const [isAdding, setIsAdding] = useState(true)
  const [values, setValues] = useState({})
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")

  let { user } = useSelector((state) => ({ ...state }))
  const authtoken = user.token

  useEffect(() => {
    setValues((previousValues) => ({
      ...previousValues,
      title: dish.title,
      description: dish.description,
      price: dish.price,
      dishId: dish._id,
    }))
  }, [dish])

  async function handleAddDish(event) {
    event.preventDefault()
    try {
      setLoading(true)
      setError("")

      const dishTitle = values.title
      const dPrice = values.price
      const dDesc = values.description
      const dishId = values.dishId

      const response = await axios({
        method: "PUT",
        url: `${process.env.REACT_APP_API}/dish/update`,
        data: {
          user,
          dPrice,
          dDesc,
          dishTitle,
          dishId,
        },
        headers: {
          authtoken,
        },
      })
      setValues(INITIAL_STATE)
      refreshMenu()
      toast.success(`מנה שונתה בהצלחה`)
      hide()
      // console.log(response)
    } catch (error) {
      toast.error("אופס, יש תקלה")
      catchErrors(error, setError)
    } finally {
      setLoading(false)
      // console.log("Finally submited");
    }
  }
  const handleChange = (event) => {
    event.persist()
    setValues((previousValues) => ({
      ...previousValues,
      [event.target.name]: event.target.value,
    }))
  }

  return isShowing
    ? ReactDOM.createPortal(
        <React.Fragment>
          <div className="modal-overlay" />
          <div
            className="modal-wrapper"
            aria-modal
            aria-hidden
            tabIndex={-1}
            role="dialog"
          >
            <div className="modal fade-in">
              <div className="modal-inner">
                <div className="modal-header">
                  <button
                    type="button"
                    className="modal-close-button"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={hide}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <div className="modal-cat">
                    <div>{catName}</div>
                  </div>
                </div>

                {/* {isAdding ? ( */}
                <>
                  <form onSubmit={handleAddDish}>
                    <div className="">
                      <div className="catName">
                        ערוך את <span>"{values.title}"</span>
                      </div>
                    </div>
                    <div className="inputCenter">
                      <label>שם המנה</label>
                      <input
                        autoFocus
                        value={values.title}
                        placeholder="שם המנה"
                        onChange={handleChange}
                        name="title"
                        type="text"
                        style={{ margin: "0 auto" }}
                        required
                        lang="he"
                      />
                    </div>
                    <div className="inputCenter">
                      <label>תאור המנה</label>
                      <textarea
                        className="inputCenter"
                        value={values.description}
                        style={{
                          // minHeight: 100,
                          width: "100%",
                          margin: "0 auto",
                        }}
                        placeholder="תאור המנה"
                        onChange={handleChange}
                        name="description"
                        type="text"
                        required
                        lang="he"
                      />
                    </div>
                    <div className="inputCenter">
                      <label>מחיר</label>
                      <input
                        value={values.price}
                        placeholder="מחיר"
                        onChange={handleChange}
                        name="price"
                        type="number"
                        style={{ margin: "0 auto" }}
                        required
                        lang="he"
                      />
                    </div>
                    <button className="btn green" type="submit">
                      <span>שמור</span>
                    </button>
                  </form>
                </>
                {/* ) : null} */}
              </div>
            </div>
          </div>
        </React.Fragment>,
        document.body
      )
    : null
}
export default ModalDish
