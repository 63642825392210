import React from "react"
import ReactDOM from "react-dom"
import "../widgets/loader.css"

const ModalQR = ({ isShowing, hide, src }) => {
  return isShowing
    ? ReactDOM.createPortal(
        <React.Fragment>
          <div className="modal-overlay" />
          <div
            className="modal-wrapper"
            aria-modal
            aria-hidden
            tabIndex={-1}
            role="dialog"
          >
            <div className="modal fade-in">
              <div className="modal-header">
                <button
                  type="button"
                  className="modal-close-button"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={hide}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              {src ? (
                <div className="qrCenter">
                  <div className="modal-qr">
                    <div className="qr-title">העתיקו הקוד לשימוש במסעדה</div>
                  </div>
                  <img className="qrImage" src={src} alt="qr-code" />
                </div>
              ) : (
                <div className="loader"></div>
              )}
            </div>
          </div>
        </React.Fragment>,
        document.body
      )
    : null
}
export default ModalQR
