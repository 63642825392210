import React from "react"

function FirstStep(props) {
  return (
    <>
      <div style={{ marginTop: ".5em" }} className="header2">
        {props.error204 && (
          <span style={{ color: "orangered", marginTop: "1em" }}>
            {props.error204}
          </span>
        )}
      </div>
      <div className="rest-create">
        <form className="form-first-step" onSubmit={props.handleSubmit}>
          <div className="input-center">
            <label htmlFor="restName">שם המסעדה</label>
            <input
              autoFocus
              placeholder="שם המסעדה"
              onChange={props.handleChange}
              name="restName"
              id="restName"
              type="text"
              required
              autoFocus
              lang="he"
            />
          </div>
          <div className="input-center">
            <label htmlFor="contact">איש/ת קשר</label>
            <input
              placeholder="שם איש/ת קשר"
              onChange={props.handleChange}
              name="contact"
              id="contact"
              type="text"
              required
              lang="he"
            />
          </div>
          <div className="input-center">
            <label htmlFor="phone">טלפון (שימוש פנימי)</label>
            <input
              placeholder="טלפון"
              onChange={props.handleChange}
              name="phone"
              id="phone"
              type="tel"
              required
              lang="he"
            />
            {props.validMessage ? (
              <h3 style={{ color: "tomato" }}>{props.validMessage}</h3>
            ) : (
              <h3 style={{ color: "#fff" }}>.</h3>
            )}
          </div>
          <button type="submit" className="red-btn">
            <span>צור תפריט</span>
          </button>
        </form>
      </div>
    </>
  )
}

export default FirstStep
