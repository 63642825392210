import React, { useState } from "react"
import Layout from "../hoc/Layout"
import { Link } from "react-router-dom"

const INITIAL_VALUE = {
  email: "",
  phone: "",
}

function Support() {
  const [values, setValues] = useState(INITIAL_VALUE)

  const handleChange = (event) => {
    const { name, value } = event.target
    setValues((prevState) => ({ ...prevState, [name]: value }))
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    // console.log(e.target.value)
  }

  const supportForm = () => (
    <div className="fade-in">
      <h3 className="inner-header">
        השאירו פרטים
        <br />
        ונחזור בהקדם
      </h3>
      <iframe
        width="282"
        height="235"
        // style="max-width:100%"
        src="https://embed.vp4.me/LandingPage,c8c6d800-d5de-4691-8e03-1d0cdf738930,115572.aspx?r=1009"
        frameBorder="0"
        allowFullScreen
        className="embediframe"
        sandbox="allow-same-origin allow-scripts allow-popups allow-forms allow-top-navigation allow-top-navigation-by-user-activation"
      ></iframe>
      <script
        type="text/javascript"
        src="https://embed.vp4.me/core/embd.min.js?v=20210209221743"
      ></script>
    </div>
  )
  return (
    <Layout>
      <div id="show-case-home">
        <div style={{ marginTop: "10%" }} className="complete-form">
          {supportForm()}
          <a href="mailto:gal@nbv.co.il">שלח מייל לתמיכה</a>
        </div>
      </div>
    </Layout>
  )
}

export default Support
