import React, { useEffect } from "react"
import { Route, Switch } from "react-router-dom"
import { useDispatch } from "react-redux"
import { auth } from "./firebase"

import { currentUser } from "./helpers/auth"
import PrivateRoute from "./components/auth/PrivateRoute"
// import AdminRoute from "./components/auth/AdminRoute"
import Home from "./screens/Home"
import Login from "./screens/auth/Login"
import Support from "./screens/Support"
import NotFound from "./components/NotFound"
import RegisterComplete from "./screens/auth/RegisterComplete"
import Forgot from "./screens/auth/Forgot"
import UserHome from "./screens/user/UserHome"
import MenuSettings from "./screens/user/MenuSettings"
import ModalDeleteDish from "./components/modals/modalDelteDish"
import Modal from "./components/modals/modal"
import MainScreen from "./screens/restaurants/MainScreen"
import PublicRest from "./screens/restaurants/PublicRest"
import BasicOne from "./screens/restaurants/samples/BasicOne"
import BasicTwo from "./screens/restaurants/samples/BasicTwo"
import Basic3 from "./screens/restaurants/samples/Basic3"
import Basic4 from "./screens/restaurants/samples/Basic4"
import EditScreen from "./screens/restaurants/EditScreen"
import TodaySpecial from "./screens/restaurants/TodaySpecial"
import About from "./screens/About"
// import { useHistory } from "react-router-dom"
// import AdminHome from "./screens/user/AdminHome";

export default function Router() {
  const dispatch = useDispatch()
  // let history = useHistory()
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        const idTokenResult = await user.getIdTokenResult()
        // console.log("user", user);

        currentUser(idTokenResult.token)
          .then((res) => {
            dispatch({
              type: "LOGGED_IN_USER",
              payload: {
                email: res.data.email,
                token: idTokenResult.token,
                role: res.data.role,
                _id: res.data._id,
              },
            })
          })
          .catch((err) => console.log(err))
      }
    })
    // cleanup
    return () => unsubscribe()
  }, [dispatch])

  return (
    <Switch>
      <Route path="/" exact component={Home} />
      <Route path="/login" exact component={Login} />
      <Route path="/support" exact component={Support} />
      <Route path="/about" exact component={About} />
      <Route path="/register/complete" exact component={RegisterComplete} />
      <Route path="/forgot/password" exact component={Forgot} />
      <Route path="/r/:id" exact component={PublicRest} />
      <Route path="/rest/:id" component={MainScreen} />
      <PrivateRoute path="/rest-edit/:id" component={EditScreen} />
      <PrivateRoute path="/today-special/:id" component={TodaySpecial} />
      <Route path="/example/basicone" component={BasicOne} />
      <Route path="/example/basictwo" component={BasicTwo} />
      <Route path="/example/basic3" component={Basic3} />
      <Route path="/example/basic4" component={Basic4} />
      <PrivateRoute path="/user/dashboard" exact component={UserHome} />
      <PrivateRoute path="/user/settings" exact component={MenuSettings} />
      <PrivateRoute path="/modaldishdelete" component={ModalDeleteDish} />
      <PrivateRoute path="/modal" component={Modal} />
      {/* <PrivateRoute path="/user/password" exact component={ResetPassword} /> */}
      {/* <PrivateRoute path="/user/events" exact component={MyEvents} /> */}
      {/* <AdminRoute path="/admin/dashboard" exact component={AdminHome} /> */}
      <Route component={NotFound} />
    </Switch>
  )
}
