import React, { useState } from "react"
import { auth, googleAuthProvider } from "../../firebase"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { toast } from "react-toastify"
import axios from "axios"
import "../widgets/loader.css"
import "../widgets/loader2.css"

function GoogleLogin() {
  let dispatch = useDispatch()
  let history = useHistory()
  const [loading, setLoading] = useState(false)
  const createUpdateUser = async (authtoken) => {
    return await axios.post(
      `${process.env.REACT_APP_API}/create-update-user`,
      {},
      {
        headers: {
          authtoken,
        },
      }
    )
  }
  const handleGoogleLogin = async () => {
    setLoading(true)
    auth
      .signInWithPopup(googleAuthProvider)
      .then(async (result) => {
        const { user } = await result
        const idTokenResult = await user.getIdTokenResult()
        // Redirect Here  *****

        // setIdToken(idTokenResult.token)
        console.log("aaaaa", idTokenResult)
        toast.success(`התחבת למערכת בהצלחה`)
        const res = await createUpdateUser(idTokenResult.token)
        const userInStorage = await user.getIdToken()
        window.localStorage.setItem(
          "user",
          JSON.stringify({ token: userInStorage, userId: res.data._id })
        )

        dispatch({
          type: "LOGGED_IN_USER",
          payload: {
            email: res.data.email,
            token: idTokenResult.token,
            role: res.data.role,
            _id: res.data._id,
          },
        })

        setLoading(false)
        history.push("/user/dashboard")
      })
      .catch((error) => {
        console.log("Login ***", error)
        toast.error(error.message)
      })
  }

  return (
    <div className="google-login">
      <button type="button" onClick={handleGoogleLogin} className="google">
        <div className="">{""}</div>
        הכנס עם גוגל
        <img src="/images/google-icon.svg" alt="google logo" />
      </button>
      {loading && <div className="loader2" />}
    </div>
  )
}

export default GoogleLogin
