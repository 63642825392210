import React from 'react';
import './sideDrawer.css';

export default function DrawerToggle({ drawerToggleOnClick }) {
    return (
        <button className="toggle-button" onClick={drawerToggleOnClick}>
            <div className="toggle-button-line" />
            <div className="toggle-button-line" />
            <div className="toggle-button-line" />
        </button>
    )
}
