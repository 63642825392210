const INITIAL_STATE_THEME = {
  menuColor: "#7f5a42",
  accentColor: "#b18367",
  bgColor: "#fff",
  border: true,
  shadow: true,
}

export function themeReducer(state = INITIAL_STATE_THEME, action) {
  switch (action.type) {
    case "SET_THEME":
      let theme
      theme = { ...state, ...action.payload }
      return theme
    default:
      return state
  }
}
