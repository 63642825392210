import React, { useState } from "react"
import { Link } from "react-router-dom"
import Categories from "./Categories"
import AddCat from "./AddCat"
import { useHistory } from "react-router-dom"
import "../../hoc/tootik.min.css"

function CatList({
  menu,
  handleAddCat,
  hadleDelete,
  refresh,
  setRefresh,
  catList,
}) {
  const [show, setShow] = useState("")
  let history = useHistory()
  function handleAddCat() {
    if (show === "") {
      setShow("show")
    } else {
      setShow("")
    }
  }

  // console.log("CatList ", menu)

  return (
    <>
      <div className="flexi">
        <div className="showMain">
          {!show ? (
            <button onClick={handleAddCat} className="btn blue">
              <span>הוסף קטגוריה</span>
            </button>
          ) : (
            <button onClick={handleAddCat} className="red-btn btn">
              <span>סגור את טופס</span>
            </button>
          )}
          <AddCat refresh={refresh} setRefresh={setRefresh} show={show} />
        </div>
        <div className="cat-list">
          <h3 className="cat-title">
            הוסיפו וערכו
            <strong> מנות וקטגוריות </strong>
            בתפריט:
          </h3>
          {catList &&
            catList.map((cat, index) => (
              <Categories
                cat={cat}
                catName={cat.title}
                index={index}
                hadleDelete={hadleDelete}
                key={index}
                menuId={menu._id}
                restName={menu.restName}
                refresh={refresh}
                setRefresh={setRefresh}
              />
            ))}
        </div>
      </div>
    </>
  )
}

export default CatList
