import React, { useState } from "react"
import catchErrors from "../utils/catchErrors"
import axios from "axios"
import { toast } from "react-toastify"
import { useSelector, useDispatch } from "react-redux"

const INITIAL_STATE = {
  category: "",
}

function AddCat({ refresh, setRefresh, show }) {
  let { user, menu } = useSelector((state) => ({ ...state }))
  const dispatch = useDispatch()
  const [values, setValues] = useState(INITIAL_STATE)
  // console.log("Add cat", menu)

  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const _id = menu.data._id

  const authtoken = user.token
  const handleChange = (event) => {
    event.persist()
    setValues((previousValues) => ({
      ...previousValues,
      [event.target.name]: event.target.value,
    }))
  }

  async function handleAddCategory(event) {
    event.preventDefault()
    try {
      setLoading(true)
      setError("")
      const menuId = _id
      const category = values.category
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_API}/category/create`,
        data: {
          user,
          menuId,
          category,
        },
        headers: {
          authtoken,
        },
      })
      setValues(INITIAL_STATE)
      toast.success(`קטגוריה נוספה בהצלחה`)
      let data = response.data
      // console.log(data)
      setRefresh(!refresh)
    } catch (error) {
      if (error.response.status === 401) {
        // Gei me to Login again
        console.log("401")
      }

      toast.error(`משהו השתבש`)
      catchErrors(error, setError)
    } finally {
      setLoading(false)
      //   console.log("Finally submited");
    }
  }

  return (
    <form onSubmit={handleAddCategory} className={`addForm ${show}`}>
      <div className="form-first-step">
        {/* <label htmlFor="category">הוסף קטגוריה </label> */}
        <input
          autoFocus
          value={values.category}
          placeholder="שם הקטגוריה"
          onChange={handleChange}
          name="category"
          id="category"
          type="text"
          style={{ margin: "0 auto" }}
          required
          lang="he"
        />
      </div>
      <button className="btn green" type="submit">
        <span>שמור</span>
      </button>
    </form>
  )
}

export default AddCat
