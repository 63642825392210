import React, { useState } from "react"
import catchErrors from "../utils/catchErrors"
import { useSelector, useDispatch } from "react-redux"
import axios from "axios"
import { toast } from "react-toastify"
import "../widgets/loader.css"

function MainImageUpload({
  menu,
  text,
  height,
  width,
  type,
  authtoken,
  refresh,
  setRefresh,
}) {
  // console.log("Menu in MainImageUpload", menu)
  const dispatch = useDispatch()
  const [values, setValues] = useState({})
  const [mediaPreview, setMediaPreview] = useState("")
  const [mediaName, setMediaName] = useState("")
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")

  // let { user } = useSelector((state) => ({ ...state }))

  // console.log("State *****", user)

  const path = type === "logo" ? "mapenu" : "main-mapenu"

  const hiddenFileInput = React.useRef(null)
  const handleClick = (event) => {
    hiddenFileInput.current.click()
  }

  const handleChange = (event) => {
    const { name, value, files } = event.target

    if (name === "imageUrl") {
      setValues((prevState) => ({
        ...prevState,
        media: { ...values.media, file: files[0] },
      }))

      if (files[0] !== undefined) {
        setMediaName(files[0].name)
        setMediaPreview(window.URL.createObjectURL(files[0]))
        setError("")
      } else if (files[0] === undefined) {
        setError("נא לבחור שוב תמונה")
      }
    } else {
      setValues((previousValues) => ({
        ...previousValues,
        [name]: value,
      }))
    }
  }
  async function handleImageUpload() {
    const data = new FormData()
    try {
      if (values.media !== "") {
        data.append("file", values.media.file)
        data.append("upload_preset", path)
        data.append("cloud_name", "dcowfz547")

        const response = await axios.post(
          process.env.REACT_APP_CLOUDINARY,
          data
        )
        // console.log(response)
        const { secure_url, public_id } = await response.data
        const imageInfo = { secure_url, public_id }

        return imageInfo
      }
    } catch (error) {
      toast.error("אופס, יש תקלה. התמונה לא עלתה לשרת")
      catchErrors(error, setError)
    }
  }

  async function handleSubmit(event) {
    try {
      event.preventDefault()
      setLoading(true)
      // console.log(values)
      setError("")
      const imageInfo = await handleImageUpload()

      if (type === "logo") {
        let logoUrl = imageInfo.secure_url
        let public_id = imageInfo.public_id
        // add the image URL to DB
        const response = await axios({
          method: "put",
          url: `${process.env.REACT_APP_API}/menu/update`,
          headers: {
            authtoken,
          },
          data: {
            public_id,
            logoUrl,
            menuId: menu._id,
          },
        })
        // console.log("DATA", response)
        let data = response.data
        dispatch({
          type: "GOT_MENU",
          payload: {
            data,
          },
        })

        toast.success(`תמונה שונתה בהצלחה`)
        setRefresh(!refresh)
      } else if (type === "main") {
        let mainUrl = imageInfo.secure_url
        let public_id = imageInfo.public_id
        // add the image URL to DB
        const response = await axios({
          method: "put",
          url: `${process.env.REACT_APP_API}/menu/update-main`,
          headers: {
            authtoken,
          },
          data: {
            public_id,
            mainUrl,
            menuId: menu._id,
          },
        })
        // console.log("DATA", response)
        let data = response.data

        dispatch({
          type: "GOT_MENU",
          payload: {
            data,
          },
        })
        toast.success(`תמונה שונתה בהצלחה`)
        setRefresh(!refresh)
      }

      setMediaPreview(null)
    } catch (error) {
      toast.error("אופס, יש תקלה")
      catchErrors(error, setError)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      {loading ? (
        <div className="loader" />
      ) : (
        <>
          <div className="main-image-upload">
            <form className="upload-form">
              <label className="logo-lable" htmlFor="logoUrl">
                <span>{text} - </span>
                גודל מומלץ :
                <br />
                {width}x{height} (בפיקסל)
              </label>
              <input
                type="file"
                accept="image/*"
                name="imageUrl"
                ref={hiddenFileInput}
                onChange={handleChange}
                style={{ display: "none" }}
              />
            </form>
            <button className="btn btn-outline" onClick={handleClick}>
              בחר קובץ
            </button>
          </div>
          <div className="log-change">
            <div className="file-name">
              {mediaName && <span>שם קובץ שנבחר: {mediaName} </span>}
            </div>
            {mediaPreview && (
              <button
                onClick={handleSubmit}
                className="btn btn-outline green blink"
                type="submit"
              >
                שמור תמונה
              </button>
            )}
          </div>
        </>
      )}
    </>
  )
}

export default MainImageUpload
