import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import axios from "axios"
import {
  updateMenuColors,
  getMenu,
} from "../../components/utils/requestFunctions"
import firebase from "firebase"
import catchErrors from "../../components/utils/catchErrors"
// import InnerBar from "../../components/header/InnerBar"
import Layout from "../../hoc/Layout"
import MainScreen from "./MainScreen"
import MainImageUpload from "../../components/restaurants/MainImageUpload"
import PickTheme from "./samples/PickTheme"
import PickFreeColors from "./samples/PickFreeColors"

const INITIAL_STATE = {
  restName: "",
  phone: "",
  contact: "",
  category: "",
}

function EditScreen() {
  const dispatch = useDispatch()
  const [menu, setMenu] = useState(null)
  const [theme, setTheme] = useState(null)
  let userFromStorage = JSON.parse(window.localStorage.getItem("user"))
  const [authtoken, setAuthToken] = useState(userFromStorage.token)
  const [error, setError] = useState("")
  const [error204, setError204] = useState("")
  const [selectedTheme, setSelectedTheme] = useState("")
  const [colors, setColors] = useState({})
  const [showSave, setShowSave] = useState(false)
  const [refresh, setRefresh] = useState(false)
  let history = useHistory()

  let user = { _id: userFromStorage.userId }

  const logout = () => {
    firebase.auth().signOut()
    window.localStorage.removeItem("registrationEmail")
    dispatch({
      type: "LOGOUT",
      payload: null,
    })
    history.push("/")
  }

  useEffect(() => {
    console.log("39 In Use Effect - EditScreen")
    getAndSetMenu(authtoken, setError204, user, dispatch)
  }, [refresh])

  const getAndSetMenu = async (authtoken, setError204, user, dispatch) => {
    // console.log("in get Menu", user)
    try {
      const data = await getMenu(authtoken, setError204, user, dispatch)
      setMenu(data)
      // console.log("data.theme", data.theme)
      setTheme(data.theme)
    } catch (error) {
      console.error("Custom Error 81 - EditScreen", error)
      if (error.response.status === 401) {
        window.localStorage.removeItem("registrationEmail")
        window.localStorage.removeItem("user")
        logout()
      }
    }
  }

  const selectTheme = (e) => {
    setShowSave(true)
    setSelectedTheme(e.target.value)
    if (e.target.value === "dark") {
      let settings = { bgColor: "#0b0b0b", border: false, shadow: true }
      setColors((previousValues) => ({
        ...previousValues,
        ...settings,
      }))
      setTheme({ ...theme, bgColor: "#0b0b0b" })
      const val = { ...theme, bgColor: "#0b0b0b" }
      dispatch({
        type: "SET_THEME",
        payload: val,
      })
      // setRefresh(!refresh)
    } else if (e.target.value === "light") {
      let settings = { bgColor: "#ffffff", border: true, shadow: true }
      setColors((previousValues) => ({
        ...previousValues,
        ...settings,
      }))
      setTheme({ ...theme, bgColor: "#ffffff" })
      const val = { ...theme, bgColor: "#ffffff" }
      dispatch({
        type: "SET_THEME",
        payload: val,
      })
      // setRefresh(!refresh)
    }
  }

  const selectColors = (e) => {
    const change = e.target.value
    setShowSave(true)
    // console.log("Change ", change)
    setColors((previousValues) => ({
      ...previousValues,
      [e.target.name]: change,
    }))
    setTheme((previousValues) => ({
      ...previousValues,
      [e.target.name]: change,
    }))
  }
  console.log("menu in Edit", menu)

  const handleSave = async (e) => {
    e.preventDefault()

    setShowSave(false)
    const menuId = menu._id
    const values = { ...theme, ...colors }
    const newTheme = await updateMenuColors(
      values,
      authtoken,
      menuId,
      dispatch,
      logout
    )

    setRefresh(!refresh)
  }

  return (
    <Layout>
      <div id="show-case" className="show-home-edit">
        <div className="panel">
          <div className="main-panel-edit">
            <h3 className="cat-title">בחירת עיצוב</h3>

            <PickTheme selectTheme={selectTheme} />

            <PickFreeColors
              colors={colors}
              selectColors={selectColors}
              setRefresh={setRefresh}
              refresh={refresh}
            />

            <MainImageUpload
              menu={menu}
              authtoken={authtoken}
              type="logo"
              text="קובץ לוגו"
              height="50"
              width="200"
              setRefresh={setRefresh}
              refresh={refresh}
            />
            <MainImageUpload
              menu={menu}
              authtoken={authtoken}
              type="main"
              text="תמונה ראשית"
              height="350"
              width="350"
              setRefresh={setRefresh}
              refresh={refresh}
            />
          </div>
          {showSave && (
            <div className="save-cont">
              <button
                className="btn btn-outline green blink"
                onClick={handleSave}
              >
                שמור שינויים
              </button>
            </div>
          )}
        </div>
        <div className="smartphone fade-in">
          <div className="main-edit">
            {menu && <MainScreen menu={menu} theme={theme} />}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default EditScreen
