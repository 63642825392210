import React, { useState } from "react"

function PickTheme({ selectTheme, selectColors }) {
  return (
    <div className="pick-a-theme">
      <div className="radio-cont smartphone small-phone dark">
        <input
          type="radio"
          onClick={selectTheme}
          name="theme"
          className=""
          value="dark"
        ></input>
        <span className="checkmark"></span>
      </div>
      <div className="radio-cont smartphone small-phone light">
        <input
          type="radio"
          onClick={selectTheme}
          name="theme"
          className=""
          value="light"
        ></input>
        <span className="checkmark"></span>
      </div>
      <div className="radio-cont smartphone small-phone color">
        <input type="radio" name="theme" className="" value="color"></input>
        <span className="checkmark-dis">צור קשר לפרטים נוספים</span>
      </div>
    </div>
  )
}

export default PickTheme
