import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { updateTodaySpecial } from "../../components/utils/requestFunctions"
import Layout from "../../hoc/Layout"
import { toast } from "react-toastify"

const INITIAL_STATE = {
  title: "",
  text: "",
  price: "",
}

function TodaySpecial(props) {
  const dispatch = useDispatch()
  let { user, menu, theme } = useSelector((state) => ({ ...state }))
  const [values, setValues] = useState(INITIAL_STATE)
  const [hide, setHide] = useState("")
  const [checked, setChecked] = useState(false)
  const [hideShadow, setHideShadow] = useState("")
  const [showSave, setShowSave] = useState(false)
  const [count, setCount] = useState(0)
  const [colors, setColors] = useState({})
  // console.log(menu.data)
  // const state = props.history.location.state
  const authtoken = user.token

  useEffect(() => {
    if (menu.data.theme.bgColor === "#ffffff") {
      let theme = {
        bgColor: "white",
        offerBGColor: "rgba(39, 39, 39, 0.85)",
        textColor: "white",
      }
      setColors(theme)
    } else {
      let theme = {
        bgColor: "rgb(39, 39, 39)",
        offerBGColor: "white",
        textColor: "rgb(39, 39, 39)",
      }
      setColors(theme)
    }
  }, [menu.data.theme.bgColor])

  useEffect(() => {
    if (menu.data.todaySpecial) {
      let specialIsTrue =
        menu.data.todaySpecial.price !== null ||
        menu.data.todaySpecial.text !== "" ||
        menu.data.todaySpecial.title !== ""
      if (specialIsTrue) {
        setValues((previousValues) => ({
          ...previousValues,
          title: menu.data.todaySpecial.title,
          text: menu.data.todaySpecial.text,
          price: menu.data.todaySpecial.price,
        }))
      }
    }
  }, [menu.data.todaySpecial])

  const setSpecial = (event) => {
    event.persist()
    setShowSave(true)
    setValues((previousValues) => ({
      ...previousValues,
      [event.target.name]: event.target.value,
    }))
  }
  const handleSave = (e) => {
    e.preventDefault()
    if (!checked) {
      setValues(INITIAL_STATE)
      const menuId = menu.data._id
      const title = ""
      const text = ""
      const price = ""
      setCount(0)
      setShowSave(false)
      updateTodaySpecial(menuId, title, text, price, authtoken, dispatch)
      toast.success(`פיצר הוסר בהצלחה`)
    }
    const menuId = menu.data._id
    const title = values.title
    const text = values.text
    const price = values.price
    // console.log(menuId, title, text, price)
    setCount(0)
    setShowSave(false)
    updateTodaySpecial(menuId, title, text, price, authtoken, dispatch)
    toast.success(`נשמר בהצלחה`)
  }
  // const handleClose = () => {
  // setHide("slidedown 1.5s")
  // setHideShadow("none")
  // }
  const handleSwitch = () => {
    setChecked(!checked)
    setShowSave(!showSave)
    setCount(count + 1)
  }

  return (
    <Layout>
      <div id="show-case" className="show-home-edit">
        <div className="panel">
          <h1 className="title">מנת היום / מבצע / משקה חינם</h1>
          <p>כאן תקבעו מה יהיה רשום בפופ-אפ של התפריט שלכם (צץ אחרי 4 שניות)</p>
          <div className="turn-on-off">
            <h3>{checked ? "הסר פיצ'ר" : "הצג פיצ'ר"}</h3>
            <label className="switch">
              <input
                type="checkbox"
                onChange={handleSwitch}
                checked={checked}
              />
              <span className="slider"></span>
            </label>
          </div>
          {checked && (
            <>
              <form className="today-form">
                <div className="input-cont">
                  <label htmlFor="specialTitle">כותרת:</label>
                  <input
                    autoFocus
                    id="specialTitle"
                    name="title"
                    placeholder="כותרת קצרה"
                    required
                    type="text"
                    lang="he"
                    onChange={setSpecial}
                    value={values.title}
                  />
                </div>
                <div className="input-cont">
                  <label htmlFor="specialText">תוכן ההצעה:</label>
                  <input
                    id="specialText"
                    name="text"
                    placeholder="תוכן ההצעה"
                    required
                    type="text"
                    lang="he"
                    onChange={setSpecial}
                    value={values.text}
                  />
                </div>
                <div className="input-cont">
                  <label htmlFor="specialPrice">מחיר:</label>
                  <input
                    id="specialPrice"
                    name="price"
                    placeholder="מחיר (אופציונאלי)"
                    type="number"
                    onChange={setSpecial}
                    value={values.price}
                  />
                </div>
              </form>
            </>
          )}

          {(showSave || count >= 1) && (
            <div className="save-cont">
              <button
                className="btn btn-outline green blink"
                onClick={handleSave}
              >
                שמור שינויים
              </button>
            </div>
          )}
        </div>
        <div className="smartphone">
          <div
            className="main-edit"
            style={{ backgroundColor: colors.bgColor }}
          >
            <div
              className="offer"
              style={{
                animation: hide,
                boxShadow: hideShadow,
                backgroundColor: colors.offerBGColor,
                color: colors.textColor,
              }}
            >
              {/* <div
                className="close-offer"
                // onClick={handleClose}
              >
                X
              </div> */}
              <div className="offer-title">{values.title}</div>
              {/* <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  width: "100%",
                }}
              >
                <div className="offer-main">{values.text}</div>
                {values.price !== "" && (
                  <div className="offer-price">{`₪${values.price}`}</div>
                )}
              </div> */}
              <div className="close-offer">
                <div>אוקי</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default TodaySpecial
