import React from "react"
import Layout from "../hoc/Layout"

export default function NotFound(rops) {
  return (
    <Layout>
      <div id="main1"></div>
      <div id="content">
        <div className="">
          <h1>404 Error</h1>
        </div>
        <div className="">
          <h2>אופססס, העמוד שביקשת לא נמצא</h2>
        </div>
      </div>
    </Layout>
  )
}
