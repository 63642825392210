import React from "react"
import "./sideDrawer.css"
import MenuItems from "../MenuItems"

export default function SideDrawer({ drawerToggleOnClick, isOpen, onClick }) {
  let drawerClasses = "side-drawer"
  if (isOpen) {
    drawerClasses = "side-drawer open"
  }

  return (
    <nav className={drawerClasses}>
      <ul>
        <MenuItems
          onClick={onClick}
          drawerToggleOnClick={drawerToggleOnClick}
        />
      </ul>
    </nav>
  )
}
