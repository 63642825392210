import React, { useState, useEffect } from "react"
import { auth } from "../../firebase"
import { toast } from "react-toastify"
import { useSelector } from "react-redux"
import Layout from "../../hoc/Layout"

const INITIAL_VALUE = {
  email: "",
}

function Forgot({ history }) {
  const [values, setValues] = useState(INITIAL_VALUE)
  const [loading, setLoading] = useState(false)
  let { user } = useSelector((state) => ({ ...state }))

  const { email } = values

  useEffect(() => {
    // console.log("out ", user)
    if (user) {
      history.push("/")
      //   console.log(user)
    }
  }, [user])

  const handleChange = (event) => {
    event.persist()
    setValues((previousValues) => ({
      ...previousValues,
      [event.target.name]: event.target.value,
    }))
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)

    const config = {
      url: process.env.REACT_APP_FORGOT_REDIRECT_URL,
      handleCodeInApp: true,
    }

    await auth
      .sendPasswordResetEmail(values.email, config)
      .then(() => {
        setValues(INITIAL_VALUE)
        setLoading(false)
        toast.success("שלחנו לינק איפוס למייל שלך")
      })
      .catch((error) => {
        console.log(error)
        setLoading(false)
        toast.error(error.message)
      })
  }

  const forgotForm = () => (
    <div className="fade-in">
      {loading && <div className="loader"></div>}
      <div className="login-main">
        <h3 className="inner-header">שחזור סיסמה</h3>
        <form className="complition" onSubmit={handleSubmit}>
          <label htmlFor="email">דוא"ל: </label>
          <input
            autoFocus
            type="email"
            name="email"
            id="email"
            value={values.email}
            onChange={handleChange}
            placeholder="שם משתמש"
            autoFocus
          />
          <div className="">
            <p></p>
          </div>
          <button
            disabled={values.email === ""}
            type="submit"
            className="red-btn"
          >
            <span>הכנס</span>
          </button>
          {/* {JSON.stringify(user)} */}
        </form>
      </div>
    </div>
  )
  return (
    <Layout>
      <div id="show-case-home">
        <div className="forgot-form">{forgotForm()}</div>
      </div>
    </Layout>
  )
}

export default Forgot
