import React, { useState, useEffect } from "react"
import axios from "axios"
import { useSelector } from "react-redux"
import { toast } from "react-toastify"
import edit from "../header/Menu/images/edit1.svg"
import "../widgets/loader.css"
import "../../hoc/tootik.min.css"

const INITIAL_STATE = {
  restName: null,
  contact: null,
  phone: null,
}

function MainSettings({ role }) {
  const [values, setValues] = useState(INITIAL_STATE)
  const [isEditingName, setIsEditingName] = useState(false)
  const [isEditingContact, setIsEditingContact] = useState(false)
  const [isEditingPhoneNumber, setIsEditingPhoneNumber] = useState(false)
  const [loading, setLoading] = useState(false)
  const [refresh, setRefresh] = useState(false)

  let { user, menu } = useSelector((state) => ({ ...state }))

  const [restInfo, setRestInfo] = useState(menu)

  const authtoken = user.token

  useEffect(() => {}, [refresh])

  console.log("in MainSettings", menu)

  const handleChange = (event) => {
    // event.persist()
    setValues((previousValues) => ({
      ...previousValues,
      [event.target.name]: event.target.value,
    }))
  }
  const handleSubmit = (event) => {
    event.preventDefault()
    setLoading(true)
    setIsEditingName(false)
    setIsEditingContact(false)
    setIsEditingPhoneNumber(false)
    console.log(values)
    const contact = values.contact || menu.data.contact
    const restName = values.restName || menu.data.restName
    const phone = values.phone || menu.data.phone
    const menuId = menu.data._id
    axios({
      method: "post",
      url: `${process.env.REACT_APP_API}/user/update`,
      data: { contact, menuId, restName, phone },
      headers: {
        authtoken,
      },
    })
      .then((response) => {
        // console.log("PROFILE UPDATE OK", response)
        toast.success("שינויים נשמרו בהצלחה")
        setValues(INITIAL_STATE)
        setLoading(false)
        setRestInfo(response)
        setRefresh(!refresh)
        console.log("Response", response)
      })
      .catch((error) => {
        console.log("Edit profile error", error)
        setLoading(false)
        toast.error(error.response)
      })
  }

  const closeEdit = () => {
    setIsEditingName(false)
    setIsEditingContact(false)
    setIsEditingPhoneNumber(false)
  }
  return (
    <div className="mainForm">
      {loading ? (
        <div className="loader" />
      ) : (
        <form onSubmit={handleSubmit}>
          <div className="equal">
            <div className="ffild">
              <span>מנוי:</span>
              <span>{role}</span>
            </div>
            {restInfo.data ? (
              <>
                <div className="ffild">
                  <label>שם המסעדה:</label>
                  <div
                    onClick={() => setIsEditingName(true)}
                    data-tootik="עריכה"
                    data-tootik-conf="info"
                    className="settings-disabled"
                  >
                    {isEditingName ? (
                      <input
                        onChange={handleChange}
                        value={values.restName}
                        name="restName"
                        type="text"
                      />
                    ) : (
                      <>
                        {" "}
                        <img className="pencil" src={edit} alt="עפרון" />
                        {restInfo.data.restName}
                      </>
                    )}
                  </div>
                </div>
                <div className="ffild">
                  <span>איש/ת קשר:</span>
                  <div
                    onClick={() => setIsEditingContact(true)}
                    data-tootik="עריכה"
                    data-tootik-conf="info"
                    className="settings-disabled"
                  >
                    {isEditingContact ? (
                      <input
                        onChange={handleChange}
                        value={values.contact}
                        name="contact"
                        type="text"
                      />
                    ) : (
                      <>
                        <img className="pencil" src={edit} alt="עפרון" />
                        {restInfo.data.contact}
                      </>
                    )}
                  </div>
                </div>
                <div className="ffild">
                  <span>טלפון:</span>
                  <div
                    onClick={() => setIsEditingPhoneNumber(true)}
                    data-tootik="עריכה"
                    data-tootik-conf="info"
                    className="settings-disabled"
                  >
                    {isEditingPhoneNumber ? (
                      <input
                        onChange={handleChange}
                        value={values.phone}
                        name="phone"
                        type="number"
                      />
                    ) : (
                      <>
                        <img className="pencil" src={edit} alt="עפרון" />
                        {`0${restInfo.data.phone}`}
                      </>
                    )}
                  </div>
                </div>
              </>
            ) : null}
          </div>
          <div className="btnGroup">
            {(values.restName || values.contact || values.phone) && (
              <button type="submit" className="btn green fade-in">
                <span>שמור</span>
              </button>
            )}
            {(isEditingPhoneNumber || isEditingContact || isEditingName) && (
              <button
                onClick={closeEdit}
                type="button"
                className="btn gray fade-in"
              >
                <span>בטל עריכה</span>
              </button>
            )}
          </div>
        </form>
      )}
    </div>
  )
}

export default MainSettings
