import React from "react"

function RestCategory({
  cat,
  menuColor,
  accentColor,
  bgColor,
  border,
  shadow,
}) {
  // console.log("Cat in RestCat", cat.dishs)
  return (
    <div
      style={{
        backgroundColor: bgColor,
        borderColor: !border && "transparent",
      }}
      className="menu-cat-cont"
    >
      <div className="menu-cat">
        <h2 style={{ color: menuColor }}>{cat.title}</h2>
      </div>
      <div className="menu-dish">
        {cat.dishes.map((dish) => (
          <div key={dish._id} className="menu-dish-item">
            <div className="dish-top">
              <div style={{ color: accentColor }} className="dish-title">
                {dish.title}
              </div>
              <div style={{ color: accentColor }} className="dish-price">
                {dish.price}
              </div>
            </div>
            <div style={{ color: accentColor }} className="dish-desc">
              {dish.description}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default RestCategory
