import React from "react"
import useModal from "../modals/useModal"
import ModalDeleteDish from "../modals/modalDelteDish"

function DishEdit({
  isEditing,
  setIsEditing,
  catId,
  dish,
  refresh,
  setRefresh,
  toggleDishEdit,
}) {
  const { isShowing, toggle } = useModal()
  const hadleDelete = () => {
    toggle()
  }
  return (
    <div className="cat-left slaid-in">
      <ModalDeleteDish
        isShowing={isShowing}
        hide={toggle}
        catId={catId}
        dish={dish}
        refresh={refresh}
        setRefresh={setRefresh}
      />
      <div
        className="info"
        onClick={() => setIsEditing(!isEditing)}
        data-tootik="סגור"
        data-tootik-conf="danger"
      >
        <img className="plus rotate" src="/images/plus.svg" alt="פלוס"></img>
      </div>
      <div data-tootik="עריכה" data-tootik-conf="info" className="">
        <img
          className="pencil"
          src="/images/pencil.svg"
          onClick={toggleDishEdit}
          alt="עפרון"
        />
      </div>
      <div data-tootik="מחיקה" data-tootik-conf="warning" className="">
        <img
          className="trash"
          src="/images/trash.svg"
          onClick={() => hadleDelete()}
          alt="פח"
        />
      </div>
    </div>
  )
}

export default DishEdit
