import React, { useState } from "react"
import catchErrors from "../utils/catchErrors"
import { useSelector } from "react-redux"
import axios from "axios"
import { useHistory } from "react-router-dom"
import { toast } from "react-toastify"
import Modal from "../modals/modelHook"
import useModal from "../modals/useModal"
import Dish from "../user/Dish"

const Categories = (props) => {
  const {
    cat,
    index,
    hadleDelete,
    menuId,
    refresh,
    setRefresh,
    catName,
  } = props
  const [isEditing, setIsEditing] = useState(false)
  const [isCatEdit, setIsCatEdit] = useState(false)
  const [values, setValues] = useState({ name: props.catName || "" })
  const [error, setError] = useState("")
  const { isShowing, toggle } = useModal()

  // console.log("cat ", cat)

  let { user } = useSelector((state) => ({ ...state }))
  const authtoken = user.token

  let history = useHistory()

  const handleChange = (event) => {
    event.persist()
    setValues((previousValues) => ({
      ...previousValues,
      [event.target.name]: event.target.value,
    }))
  }

  async function handleNameChange(event) {
    event.preventDefault()
    console.log("values", values)
    if (!values.newName) {
      console.log("+++")
      return
    }
    await handleSave()
  }

  async function handleSave() {
    setIsCatEdit(false)

    const catId = cat._id
    const updatedName = values.newName

    try {
      const response = await axios({
        method: "PUT",
        url: `${process.env.REACT_APP_API}/category/catupdate`,
        data: {
          user,
          menuId,
          catName,
          updatedName,
          catId,
        },
        headers: {
          authtoken,
        },
      })
      setValues({})
      toast.success(`קטגוריה שונתה בהצלחה`)
      setRefresh(!refresh)
      // console.log("Saved: ", response.data)
    } catch (error) {
      if (error.response.status === 401) {
        window.localStorage.removeItem("registrationEmail")
        window.localStorage.removeItem("user")
        toast.error("יש להתחבר למערכת שוב")
        catchErrors(error, setError)
      } else {
        toast.error("אופס, יש תקלה")
        catchErrors(error, setError)
      }
    }
  }
  function refreshMenu() {
    setRefresh(!refresh)
  }
  return (
    <div className="catItemContainet">
      <div className="cat-head">
        <Modal
          catId={cat._id}
          catName={catName}
          isShowing={isShowing}
          hide={toggle}
          menuId={menuId}
          refreshMenu={refreshMenu}
        />
        {isCatEdit ? (
          <form onSubmit={handleNameChange}>
            <input
              autoFocus
              className="cat-input"
              onChange={handleChange}
              name="newName"
              type="text"
              placeholder={catName}
              // value={catName}
            ></input>
          </form>
        ) : (
          <>
            <h3
              onClick={() => setIsCatEdit(!isCatEdit)}
              className="catName"
              data-tootik="ערוך קטגוריה"
              data-tootik-conf="info"
            >
              {cat.title}:
            </h3>
          </>
        )}
        <div className="cat-left">
          {isCatEdit && (
            <div
              data-tootik="מחיקה"
              data-tootik-conf="info"
              className="trash-con"
            >
              <img
                className="trash shake"
                src="/images/trash.svg"
                onClick={() => hadleDelete(cat)}
                alt="פח"
              />
            </div>
          )}
          <div>
            <button
              // onClick={toggle}
              onClick={(() => setIsEditing(!isEditing), toggle)}
              className="btn btn-outline blue"
            >
              הוסף מנה
            </button>
          </div>
        </div>
      </div>
      {cat.dishes ? (
        <div className="dish-list">
          {cat.dishes.map((dish) => (
            <Dish
              key={dish._id}
              refreshMenu={refreshMenu}
              menuId={menuId}
              catId={cat._id}
              dish={dish}
              refresh={refresh}
              setRefresh={setRefresh}
            />
          ))}
        </div>
      ) : null}
    </div>
  )
}

export default Categories
