import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import logo from "../restaurants/samples/images/change1.png"
import top1 from "../restaurants/samples/images/change2.png"
import RestLayout from "../../hoc/RestLayout"
import { useDispatch, useSelector } from "react-redux"
import RestCategory from "../../components/restaurants/RestCategory"
import HeadBasicOne from "./samples/head/HeadBasicOne"
import BasicOne from "../restaurants/samples/BasicOne"

function MainScreen({ menu, theme, refresh, setRefresh }) {
  let userFromStorage = JSON.parse(window.localStorage.getItem("user"))
  const [logoImg, setLogoImg] = useState(logo)
  const [mainImage, setMainImage] = useState(top1)
  let { user } = useSelector((state) => ({ ...state }))

  let history = useHistory()
  const [error, setError] = useState("")
  // console.log("menu in MainScreen", menu)
  const [themeSetings, setThemeSetings] = useState(null)

  const authtoken = userFromStorage.token

  useEffect(() => {
    // console.log("theme", menu.theme)
    setThemeSetings(theme)
  }, [theme])
  useEffect(() => {
    // console.log("theme", menu.theme)
    if (menu.logoUrl) {
      setLogoImg(menu.logoUrl)
    }
    if (menu.mainUrl) {
      setMainImage(menu.mainUrl)
    }
  }, [menu.logoUrl, menu.mainUrl])

  return (
    <>
      {!themeSetings ? (
        <>
          <BasicOne menu={menu} />
        </>
      ) : (
        <RestLayout bgColor={themeSetings.bgColor}>
          <HeadBasicOne
            logo={logoImg}
            top1={mainImage}
            menuColor={themeSetings.menuColor}
          />

          <div
            id="menu-main1"
            style={{ backgroundColor: themeSetings.bgColor }}
          >
            {menu.categories.map((cat, index) => (
              // console.log(cat)
              <RestCategory
                cat={cat}
                index={index}
                key={cat._id}
                menuColor={themeSetings.menuColor}
                accentColor={themeSetings.accentColor}
                bgColor={themeSetings.bgColor}
                border={themeSetings.border}
                shadow={themeSetings.shadow}
              />
            ))}
          </div>

          <div
            style={{ backgroundColor: themeSetings.menuColor }}
            id="menu-footer"
          >
            כל הזכויות שמורות © NBV 2021
          </div>
        </RestLayout>
      )}
    </>
  )
}

export default MainScreen
