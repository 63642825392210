import React, { useState } from "react"
import MainContent from "../components/MainContent"
import ShowCase from "../components/ShowCase"
import Layout from "../hoc/Layout"

function Home() {
  return (
    <Layout>
      <ShowCase />
      <MainContent />
      <div className="bottom-content">
        <p className="text-center">
          על-מנת להתחיל ולהקים את התפריט שלכם יש להיכנס ל"מפניו" עם טאבלט /
          לפטופ / מחשב נייח
        </p>
      </div>
    </Layout>
  )
}

export default Home
