import React, { useState } from "react"

function HeadBasicOne({ logo, top1, menuColor, bgColor, midColor }) {
  // console.log(menuColor)
  const [on, setOn] = useState(false)
  return (
    <div id="menu-head-2">
      <div className="rest-bar" style={{ backgroundColor: bgColor }}>
        <div
          onClick={() => setOn(!on)}
          className={`hamburger ${on ? "on" : ""}`}
        >
          <div
            style={{ backgroundColor: menuColor }}
            className={`line-one bg-${menuColor}`}
          ></div>
          <div
            style={{ backgroundColor: midColor }}
            className={`line-two bg-${menuColor}`}
          ></div>
          <div
            style={{ backgroundColor: menuColor }}
            className={`line-three bg-${menuColor}`}
          ></div>
        </div>
        <img src={logo} alt="לוגו" />
        <div className="spacer"></div>
      </div>
      <div className="rest-pic-main">
        <div className="">
          <img src={top1} alt="תמונה ראשית" />
        </div>
        <div className="inner-hero">
          <div className="logo-rest-cont">
            {/* <h1 className="logo-rest">{menu.restName}</h1> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default HeadBasicOne
