import React from "react"
import Layout from "../hoc/Layout"

function About() {
  return (
    <Layout>
      <div id="show-case-home">
        <div className="about-us">
          <h3 className="title-about">! For The Love of Food</h3>
          <h4 className="title">מדוע הקמנו את Mapenu?</h4>
          <p>
            בעולם שמשתנה בתדירות כך כך גבוהה, נולד הרעיון להנגיש למסעדות בישראל
            את העולם הדיגיטלי שמטבע הדברים תופס יותר ויותר משמעות בחיי היום יום
            שלנו.
          </p>
          <p>
            Mapenu שמה לעצמה למטרה לאפשר למסעדות להתחבר לדיגיטל ובכך לשמור על
            קשר אינטראקטיבי עם הסועדים.
          </p>
          <p>
            הקורונה שינתה את כללי המשחק וחשנו בצורך להיות שם עבור עולם המסעדנות
            שכולנו מאוד אוהבים
          </p>
          <p>שלכם באהבה, צוות NBV</p>
          <p>נתראה במסעדות</p>
          <div className="chef">
            <img src="/images/chef.jpg" alt="שף" />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default About
