export function userReducer(state = null, action) {
  switch (action.type) {
    case "LOGGED_IN_USER":
      const user = { ...state, ...action.payload }
      return user
    case "LOGOUT":
      return action.payload
    default:
      return state
  }
}
