import React, { useState } from "react"
import ReactDOM from "react-dom"
import catchErrors from "../utils/catchErrors"
import axios from "axios"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { toast } from "react-toastify"

const ModalDeleteDish = ({
  catId,
  dish,
  hide,
  isShowing,
  refresh,
  setRefresh,
}) => {
  let { user } = useSelector((state) => ({ ...state }))
  let history = useHistory()

  const [error, setError] = useState("")
  const dishId = dish._id

  const authtoken = user.token

  async function handleDelete() {
    try {
      const response = await axios({
        method: "DELETE",
        url: `${process.env.REACT_APP_API}/dish/delete`,
        data: {
          catId,
          dishId,
        },
        headers: {
          authtoken,
        },
      })
      toast.success(`נמחק בהצלחה`)
      setRefresh(!refresh)
      hide()
    } catch (error) {
      toast.error("אופס, יש תקלה")
      catchErrors(error, setError)
    }
  }

  return isShowing
    ? ReactDOM.createPortal(
        <React.Fragment>
          <div className="modal-overlay" />
          <div
            className="modal-wrapper"
            aria-modal
            aria-hidden
            tabIndex={-1}
            role="dialog"
          >
            <div className="modal fade-in">
              <div className="modal-inner">
                <div className="modal-header">
                  <button
                    type="button"
                    className="modal-close-button"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={hide}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <h3>
                  ? בטוח למחוק את {""} {dish.title}
                </h3>
                <div className="buttonsModel">
                  <button
                    onClick={() => handleDelete(dish)}
                    style={{ marginBottom: "1em" }}
                    className="btn red-btn"
                  >
                    <span>מחק</span>
                  </button>
                  <button
                    onClick={() => {
                      hide()
                    }}
                    className="btn gray"
                  >
                    <span>בטל</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>,
        document.body
      )
    : null
}

export default ModalDeleteDish
