import React, { useState } from "react"
import RestLayout from "../../../hoc/RestLayout"
import RestCategory from "../../../components/restaurants/RestCategory"
import top1 from "./images/cafe2.png"
import logo from "./images/logo-coffe.png"
import HeadBasicTwo from "./head/HeadBasicOne"

const INITIAL_STATE = [
  {
    categories: [
      {
        title: "ארוחות בוקר",
        _id: "1",
        dishes: [
          {
            _id: "1.1",
            price: "48",
            description: "אחלה ארוחת בוקר, אני אומר לכם...",
            title: "בוקר ישראלי זוגי",
          },
          {
            _id: "1.2",
            price: "18",
            description: "בוקר פינוק מול הים",
            title: "כל דברים הטובים",
          },
          {
            _id: "1.3",
            price: "38",
            description: "אחלה ארוחת בוקר, אני אומר לכם...",
            title: "פנקייק מתוק",
          },
        ],
      },
      {
        title: "ארוחות צהריים",
        _id: "2",
        dishes: [
          {
            _id: "2.1",
            price: "47",
            description: "אחלה ארוחת בוקר, אני אומר לכם...",
            title: "בוקר ישראלי זוגי",
          },
        ],
      },
    ],
  },
]

function Basic3() {
  const [menu, setMenu] = useState(INITIAL_STATE)
  console.table(menu[0])
  return (
    <RestLayout>
      <HeadBasicTwo
        logo={logo}
        top1={top1}
        menuColor="#c98c67"
        bgColor="#fff"
        midColor="#855e46"
      />
      {menu ? (
        <div id="menu-main">
          {menu[0].categories.map((cat, index) => (
            <RestCategory cat={cat} index={index} key={cat._id} />
          ))}
        </div>
      ) : (
        <>
          <h2>null</h2>
        </>
      )}

      <div id="menu-footer1">כל הזכויות שמורות © גל אגאי 2021</div>
    </RestLayout>
  )
}

export default Basic3
