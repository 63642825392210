import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import axios from "axios"
import RestLayout from "../../hoc/RestLayout"
import { toast } from "react-toastify"
import RestCategory from "../../components/restaurants/RestCategory"
import HeadBasicOne from "./samples/head/HeadBasicOne"
import catchErrors from "../../components/utils/catchErrors"

function PublicRest() {
  const { id } = useParams()
  const [menu, setMenu] = useState()
  const [error, setError] = useState("")
  const [colors, setColors] = useState({})
  const [hide, setHide] = useState(false)

  useEffect(() => {
    // console.log("Page", id)
    loadMenu()
  }, [])

  const loadMenu = async () => {
    // console.log("In loadMenu")
    try {
      const savedMenu = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_API}/rest/${id}`,
      })
      setMenu(savedMenu.data)
      console.log(savedMenu.data.theme.bgColor)
      if (savedMenu.data.theme.bgColor === "#ffffff") {
        let theme = {
          bgColor: "#ffffff",
          offerBGColor: "rgba(39, 39, 39, 0.9)",
          textColor: "#ffffff",
        }
        setColors(theme)
      } else {
        let theme = {
          bgColor: "rgb(39, 39, 39)",
          offerBGColor: "rgba(255,255,255,.9)",
          textColor: "rgb(39, 39, 39)",
        }
        setColors(theme)
      }
      // console.log(savedMenu.data)
    } catch (error) {
      toast.error(" אופס, יש תקלה יש לפנות למנהל האתר")
      catchErrors(error, setError)
    }
  }

  const handleClose = () => {
    setHide(true)
  }
  return (
    <>
      {menu && (
        <RestLayout bgColor={menu.theme.bgColor}>
          <HeadBasicOne
            logo={menu.logoUrl}
            top1={menu.mainUrl}
            menuColor={menu.theme.menuColor}
          />
          <div id="menu-main1">
            {menu.categories.map((cat, index) => (
              // console.log(cat)
              <RestCategory
                cat={cat}
                index={index}
                key={cat._id}
                menuColor={menu.theme.menuColor}
                accentColor={menu.theme.accentColor}
                bgColor={menu.theme.bgColor}
                // border={themeSetings.border}
                // shadow={themeSetings.shadow}
              />
            ))}
          </div>

          {menu.todaySpecial && (
            <>
              <div
                // className="offer"
                className={`offer ${hide ? "hide-offer" : ""}`}
                style={{
                  backgroundColor: colors.offerBGColor,
                  // backgroundColor: "#ffffff",
                  color: colors.textColor,
                  // color: menu.theme.bgColor,
                }}
              >
                <div className="offer-title">{menu.todaySpecial.title}</div>
                {/* <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    width: "100%",
                    // marginBottom: "2.2rem",
                  }}
                >
                  <div className="offer-main">{menu.todaySpecial.text}</div>
                  {menu.todaySpecial.price !== "" && (
                    <div className="offer-price">{`₪${menu.todaySpecial.price}`}</div>
                  )}
                </div> */}
                <div className="close-offer" onClick={handleClose}>
                  <div>אוקי</div>
                </div>
              </div>
            </>
          )}
          <div
            style={{ backgroundColor: menu.theme.menuColor }}
            id="menu-footer"
          >
            כל הזכויות שמורות © NBV 2021
          </div>
        </RestLayout>
      )}
    </>
  )
}

export default PublicRest
