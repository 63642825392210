import React, { useState } from "react"
import RestLayout from "../../../hoc/RestLayout"
import RestCategory from "../../../components/restaurants/RestCategory"
import top1 from "./images/dark2.png"
import logo from "./images/japan.png"
import HeadBasicTwo from "./head/HeadBasicTwo"

const INITIAL_STATE = [
  {
    categories: [
      {
        title: "ראמן",
        _id: "1",
        dishes: [
          {
            _id: "1.1",
            price: "43",
            description: "כל הלילה על האש...",
            title: "ראמן הבית",
          },
          {
            _id: "1.2",
            price: "47",
            description: "ראמן ספייסי עם חתיכות עוף",
            title: "ראמן עוף פרגית",
          },
          {
            _id: "1.3",
            price: "47",
            description: "3 סוגי פטריות ופירות ים",
            title: "ראמן דייגים",
          },
        ],
      },
      {
        title: "קומבינציות דג",
        _id: "2",
        dishes: [
          {
            _id: "2.1",
            price: "87",
            description: "כל הדברים הטובים",
            title: "קומבינציה זוגית",
          },
          {
            _id: "2.2",
            price: "57",
            description: "ירקות, חביתה ופטריות",
            title: "קומבינציה צימחונית",
          },
          {
            _id: "2.3",
            price: "89",
            description: "בייצי דגים ונגיעות של עוד",
            title: "קומבינציה טוקיו",
          },
          {
            _id: "2.4",
            price: "147",
            description: "שילוב של דברים טעימים",
            title: "קומבינציה ענקית",
          },
          {
            _id: "2.5",
            price: "249",
            description: "עד 15 איש",
            title: "מגש מסיבה",
          },
        ],
      },
      {
        title: "קינוחים",
        _id: "3",
        dishes: [
          {
            _id: "3.1",
            price: "71",
            description: "מחית שעועית אדומה עם חלב עיזים ממוצק",
            title: "קינוח יפני מסורתי",
          },
          {
            _id: "3.2",
            price: "55",
            description: "המיוחד של השף",
            title: "רול טמגו",
          },
          {
            _id: "3.3",
            price: "66",
            description: "מלא בשוקולד קיטקט",
            title: "רול וואסאבי וקיטקט",
          },
        ],
      },
    ],
  },
]

function BasicOne() {
  const [menu, setMenu] = useState(INITIAL_STATE)
  console.table(menu[0])
  return (
    <RestLayout>
      <HeadBasicTwo
        logo={logo}
        top1={top1}
        menuColor="#fff"
        bgColor="#0b0b0b"
        midColor="#dc143c"
      />
      {menu ? (
        <div id="menu-main2">
          {menu[0].categories.map((cat, index) => (
            <RestCategory cat={cat} index={index} key={cat._id} />
          ))}
        </div>
      ) : (
        <>
          <h2>null</h2>
        </>
      )}

      <div id="menu-footer">כל הזכויות שמורות © גל אגאי 2021</div>
    </RestLayout>
  )
}

export default BasicOne
